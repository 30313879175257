.ac-results .suggestions.half  {
    width: 50%;
    display: inline-block;
}
.ac-results .suggestions ul {
    /*width: 400px;*/
    list-style: none;
    margin: 0;
    padding: 0;
}
.ac-results .suggestions ul li {
    padding: 10px 10px;
}
.ac-results .suggestions ul li:hover,
.ac-results .suggestions ul li:hover a {
    background: #eee;
    text-decoration: underline;
    color: blue;
}
.ac-results .suggestions ul li a {
    text-decoration: none;
    color: black;
}