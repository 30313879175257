.ac-results {
    font-family: Arial;
    font-size: 0.8em;
    position: relative;
    border: solid 1px #eee;
    box-shadow: 2px 2px #eee;
    border-radius: 0px 0px 5px 5px;
    padding: 5px;
    width: 700px;
    display: block;
}
.ac-results.invisible {
    display: none;
}